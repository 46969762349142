import { useEffect } from 'react'
import ActionCable from 'actioncable'
import { webSocketUrl } from '../Utils/instance2'

let cable

function getCableInstance() {
	if (!cable) {
		cable = ActionCable.createConsumer(webSocketUrl)
	}
	return cable
}

function useWebSocket(channelParams, onReceive, onConnect, onDisconnect) {
	useEffect(() => {
		const cable = getCableInstance()
		const channel = cable.subscriptions.create(channelParams, {
			connected() {
				console.log('Connected to WebSocket channel')
				onConnect && onConnect()
			},
			disconnected() {
				console.log('Disconnected from WebSocket channel')
				onDisconnect && onDisconnect()
			},
			received(data) {
				console.log("Received data", data)
				onReceive && onReceive(data)
			}
		})

		return () => {
			try {
				console.log('Cleaning up WebSocket subscription')
				cable.subscriptions.remove(channel)
			} catch (error) {
				console.error('Error removing WebSocket subscription:', error)
			}
		}
	}, [channelParams, onReceive, onConnect, onDisconnect])
}

export default useWebSocket