import { Typography, TextField, Box } from '@mui/material'

const css = {
  container: {
  },
  input: {
    position: 'relative',
    borderRadius: 2,
    '&:hover': {
      '& > *': {
        textDecoration: 'underline',
      }
    },
  }
}


export default function SeamlessInput({ onBlur, allowNumbers = false, onChange, label, value, disabled, type = "text", name, placeholder, width = 400, color = '#6a6a6a42', variant = "subtitle1", fontColor = "#6A6A6A", padding = 2, onKeyPress, focus = false, elipsis = false }) {

  const useless = disabled && !value
  if (useless) return null

  function handleKeyPress(event) {
    if (allowNumbers) {
      if (!/^[0-9\b.]+$/.test(event.key) && event.key !== 'Enter' && event.key !== 'Backspace') {
        event.preventDefault()
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault()
      onBlur()
    }
  }

  return (
    <Box sx={{ ...css.container, maxWidth: width, width: "100%" }}>
      <Typography variant={variant} fontSize={"0.95rem"} fontFamily={"'Poppins', sans-serif"} >{label}</Typography>
      {disabled ?
        <Typography variant={variant} color={fontColor} >
          {value || placeholder}
        </Typography>
        :
        <TextField

          onChange={onChange}
          onBlur={onBlur}
          value={value || ""}
          name={name}
          type={type}
          multiline={!elipsis}
          sx={{
            ...css.input,
            background: color,
            // Root class for the input field
            "& .MuiOutlinedInput-root": {
              color: fontColor,
              fontFamily: "'Poppins', sans-serif",
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "1.75",
              fontWeight: "bold",
              padding: padding,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderWidth: "0px",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "0px",
            },
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          autoFocus={focus}
          fullWidth
        />
      }
    </Box>

  )

}
